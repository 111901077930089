import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Tabs,
  Tab,
} from 'carbon-components-react';
import { InfoSection, InfoCard } from '../../components/Info';
import Globe32 from '@carbon/icons-react/lib/globe/32';
import PersonFavorite32 from '@carbon/icons-react/lib/person--favorite/32';
import Application32 from '@carbon/icons-react/lib/application/32';
import { Image32 } from '@carbon/icons-react';

const props = {
  tabs: {
    selected: 0,
    role: 'navigation',
  },
  tab: {
    role: 'presentation',
    tabIndex: 0,
  },
};

const LandingPage = () => {
  return (
    <div className="bx--grid bx--grid--full-width landing-page">
      <div className="bx--row landing-page__banner">
        <div className="bx--col-lg-16">
          <Breadcrumb noTrailingSlash aria-label="Page navigation">
            <BreadcrumbItem>
              <a href="/">Getting started</a>
            </BreadcrumbItem>
          </Breadcrumb>
          <h1 className="landing-page__heading">
            Identity Proofing App
          </h1>
        </div>
      </div>
      <div className="bx--row landing-page__r2">
        <div className="bx--col bx--no-gutter">
          <Tabs {...props.tabs} aria-label="Tab navigation">
            <Tab {...props.tab} label="Identity Proofing">
              <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
                <div className="bx--row landing-page__tab-content">
                  <div className="bx--col-md-4 bx--col-lg-7">
                    <h2 className="landing-page__subheading">
                      You need to perform Identity Proofing to continue.
                    </h2>
                    <p className="landing-page__p">
                      In order to continue using this site, you need to prove your identity using an outside vendor.
                    </p>
                    <Button href="https://colton-prod.ice.ibmcloud.com/profile/v3.0/flows/identity_proofing/identity-proofing-test?callback_url=https%3A%2F%2Fapp.identityproofing.coltonwilliams.net%2F%23%2Fidentity-proofing-response" type="button">Prove Identity</Button>
                    <p>NOTE: You need to have a browser extension to add the CORS headers in order for this to work</p>
                    <p><strong>
                      DISCLAIMERS: The intent of this application is for demonstration purposes only.<br/>
                      Please be aware you are required to provide a document id and other attributes to perform this demonstration.<br/>
                      If you are not willing to provide a document id or requested attributes, please do not continue.<br/>
                      By performing this demonstration, you agree that you are okay with providing the requested information.<br/>
                      The information in no way will be used for any marketing or outreach and is solely meant for demonstrating how id proofing works with IBM Security Verify SaaS.
                      </strong></p>
                  </div>
                  <div className="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8">
                    <img
                      className="landing-page__illo"
                      src={`${process.env.PUBLIC_URL}/tab-illo.png`}
                      alt="Carbon illustration"
                    />
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      {/* <InfoSection heading="The Principles of Software Engineering" className="landing-page__r3">
        <InfoCard
          heading="Programming is Fun"
          body="Whether it is solving problems or making a video game, programming is a great way to make something new. It's a lot like playing with Legos!"
          icon={<PersonFavorite32 />}
        />
        <InfoCard
          heading="Programming is Artistic"
          body="Designing a website or app to look the way you want is a great way to be artistic. You can design characters in a video game or a website with funny pictures. There are lots of ways to be artistic!"
          icon={<Image32 />}
        />
        <InfoCard
          heading="Programming is EVERYWHERE!"
          body="From video games on phones or iPads, the cars on the street, or even a stores website, Programming is everywhere in our lives. There are endless places where programming can make the workd a better place!"
          icon={<Globe32 />}
        />
      </InfoSection> */}
    </div>
  );
};

export default LandingPage;
